<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                          placeholder="Tìm tên,mã hoặc SĐT học viên" autocomplete="false"></el-input>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="pickStart"
                    :picker-options="pickerStartOptions"
                    placeholder="Từ ngày">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.endDate"
                    type="date"
                    ref="picker"
                    :onPick="pickEnd"
                    format="yyyy-MM-dd"
                    @change="pickEnd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerEndOptions"
                    placeholder="Đến ngày">
                </el-date-picker>
              </el-col>
              <el-col v-if="isGDKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                           placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name_alias ? item.name_alias : item.name"
                      placeholder="Chọn cơ sở"
                      :value="item.id">
                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.ben_thu_tien" filterable class="w-100 mt-3"
                           placeholder="Bên thu tiền" @change="handleBenThuTien">
                  <el-option
                      v-for="item in ben_thu_tien"
                      :key="item.id"
                      :label="item.name"
                      placeholder="Chọn bên thu tiền"
                      :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col v-if="isGDKV" :xs="24" :sm="12" :md="8" :lg="5" :xl="3">
                <tree-select class="w-100 mt-3" @input="chooseSale"
                             :show-count="true"
                             search-nested v-model="form.sale"
                             placeholder="Chọn nhân sự muốn xem..."
                             :options="options"
                             :normalizer=normalizer
                >
                  <!--eslint-disable-->
                  <label slot="option-label"
                         slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                         :class="labelClassName">
                    <img :src="getImageHierarchy(node.raw.profile.account_type.id)" alt=""> <span> {{
                      node.label
                    }} </span>
                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                  </label>
                </tree-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.capacity" filterable class="w-100 mt-3"
                        placeholder="Loại doanh số">
                        <el-option v-for="item in capacityDefine"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit"><i
                    v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <div class="mb-5 mt-5">
              <h4>Tổng doanh số:  <b class="text-danger">{{ total | formatVND }}</b></h4>
              <h4> Doanh số đội:  <b class="text-danger">{{totalTeam | formatVND }}</b></h4>
              <h4> Doanh số digital: <b class="text-danger">{{ totalDigital | formatVND }}</b></h4>
            </div>
            <!-- datatable start -->
            <el-table
                :data="tableData.data"
                stripe
                v-loading="loading"
                border
                :default-sort="{prop: 'date', order: 'descending'}"
                tableSize=" table-bordered table-striped table-hover"
                class="table-hover"
                style="width: 100%">
              <el-table-column label="#" type="index" width="50" :index="indexMethod">
              </el-table-column>
              <el-table-column
                  prop="id"
                  label="Mã hóa đơn"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="Họ và tên"
                  width="180">
              </el-table-column>
              <el-table-column
                  label="Mã học viên">
                <template v-slot="scope">
                  <span style="margin-left: 10px">
                    {{ scope.row.user_id + 100000 }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="Điện thoại">
              </el-table-column>
              <el-table-column
                  prop="contract_id"
                  label="Mã hợp đồng">
              </el-table-column>
              <el-table-column
                  label="Bên thu tiền">
                <template v-slot="scope">
                  <span style="margin-left: 10px">
                    {{ scope.row.ben_thu_tien ? 'Edutalk' : 'Trung tâm' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Số tiền nộp">
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <span style="margin-left: 10px">{{ scope.row.amount | formatVND }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Phí trả góp" width="150">
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <span style="margin-left: 10px" v-if="scope.row.is_installment == 1">{{ -(scope.row.amount - scope.row.amount_installment) |  formatVND }}</span>
                  <span style="margin-left: 10px" v-else>0đ</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  label="Doanh số"
              >
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <span style="margin-left: 10px">
                      {{ scope.row.amount_installment |  formatVND }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="branch"
                  label="Chi nhánh quyết toán" width="160">
                <template v-slot="scope">
                  <span style="margin-left: 10px" class="text-break">{{
                      scope.row.chi_nhanh_hoc ? scope.row.chi_nhanh_hoc.name : ''
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Ngày nộp">
                <template v-slot="scope">
                  <span style="margin-left: 10px">{{ scope.row.dated_paid | formatDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Người tạo">
                <template v-slot="scope">
                  <span style="margin-left: 10px  " class="text-break">{{ scope.row.create_user.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Loại doanh số">
                <template v-slot="scope">
                  <span style="margin-left: 10px  " class="text-break">{{ scope.row.type_sale == 1 ? "Doanh số key" : "Doanh số Digital" }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="edutalk-paging">
              <div class="block">
                <!--eslint-disable-->
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="paging.per_page"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>
            <!-- datatable end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {PAYMENT_LOCATION} from "@/core/services/store/payment.module";
import {BRANCH_LIST, HIERARCHY} from "@/core/services/store/service.module";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import moment from "moment-timezone";

const _ = deepdash(lodash);

export default {
  name: "PaymentLocation",
  components: {},
  data() {
    return {
      moment: moment,
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      pickerEndOptions: {
        disabledDate: this.disabledEndDate
      },
      paging: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      dialogImageUrl: '',
      loading: false,
      currentPage: 1,
      selectDate: '',
      defaultValue: '',
      currentDate: new Date(),
      isGDKV: false,
      imageHierarchy: {
        '5': 'media/hierarchy/gdvp.png',
        '1': 'media/hierarchy/sl.png',
        '2': 'media/hierarchy/se.png',
        '3': 'media/hierarchy/s.png',
        '4': 'media/hierarchy/si.png'
      },
      total: {tong: 0, so_luot: 0},
      totalTeam: 0,
      totalDigital: 0,
      options: [],
      form: {
        name: '',
        startDate: null,
        endDate: null,
        view_as: '',
        branch_id: '',
        sale: null,
        ben_thu_tien: '',
      },
      branches: [],
      ben_thu_tien: [
        {
          id: 0,
          name: 'Trung tâm'
        },
        {
          id: 1,
          name: 'Edutalk'
        }
      ],
      watchAs: [
        {
          id: 1,
          value: 'Đội kinh doanh',
          label: 'Đội'
        },
        {
          id: 2,
          value: 'Cá nhân',
          label: 'Cá nhân'
        }
      ],
      capacityDefine: 
      [
        {id: 2, name: 'TVV digital'},
        {id: 0, name: 'Tất cả'}
      ],
      tableData: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách"},
      {title: "Đóng tiền tại điểm", icon: 'far fa-money-bill'}
    ]);
    let date = new Date();
    this.form.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
    this.form.endDate = this.$moment().format('YYYY-MM-DD');
    this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.toDate = new Date();
    if (this.currentUser.profile.khuvuc_id > 0 && this.currentUser.profile.account_type.name === 'GĐKV') {
      this.isGDKV = true;
      this.getCenter();
    } else {
      this.watchAs.unshift({
        id: 0,
        value: 'TPhòng/Giám đốc',
        label: 'TP/GĐ'
      });
      this.form.view_as = null;
      this.querySever();
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    getImageHierarchy(account_type) {
      return this.imageHierarchy[account_type];
    },
    handleCurrentChange(val) {
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 10 * (this.paging.current_page - 1) + (index + 1);
    },
    submit() {
      this.querySever();
    },
    getCenter() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              this.querySever({branch_id: this.form.branch_id});
              this.handleChangeBranch()
            }
          })

    },
    handleChangeBranch() {
      if (this.form.branch_id > 0) {
        this.$store.dispatch(HIERARCHY, {branch_id: this.form.branch_id})
            .then((response) => {
              this.form.sale = null;
              this.options = response.data
            })
      } else {
        this.form.sale = null;
        this.form.view_as = null;
        this.options = [];
      }
    },

    handleBenThuTien() {
      this.querySever();
    },
    querySever(customProperties) {
      this.loading = true;
      let params = this.mergeParams(customProperties);
      console.log('paramsparams',params);
      this.currentPage = 1;
      this.$store.dispatch(PAYMENT_LOCATION, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.tableData = response.data.list;
              this.total = response.data.total;
              this.totalTeam = response.data.team.tong;
              this.totalDigital = response.data.digital.tong;
              this.paging = {
                total: response.data.list ? response.data.list.total : '',
                count: response.data.list ? response.data.list.total : '',
                per_page: response.data.list ? response.data.list.per_page : '',
                current_page: response.data.list ? response.data.list.current_page : '',
                last_page: response.data.list ? response.data.list.last_page : '',
              }
            }
            this.loading = false;
          })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
        view_as: this.form.view_as,
      };
      if (this.form.startDate) {
        params = _.merge(params, {startDate: this.form.startDate})
      }
      if (this.form.endDate) {
        params = _.merge(params, {endDate: this.form.endDate})
      }
      if (this.form.name) {
        params = _.merge(params, {search: this.form.name})
      }
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      if (this.form.sale) {
        params = _.merge(params, {sale: this.form.sale})
      }
      if (this.form.ben_thu_tien == 0 || this.form.ben_thu_tien == 1) {
        params = _.merge(params, {ben_thu_tien: this.form.ben_thu_tien})
      }
      if (this.form.capacity == 0 || this.form.capacity == 2) {
        params = _.merge(params, {capacity: this.form.capacity})
      }
      params = _.merge(params, customProperties);
      return params;
    },
    chooseSale() {
      if (!this.form.sale) {
        this.form.view_as = null
      }
    },
    normalizer: function (node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    /* date picker methods */
    pickStart(date) {
      this.fromDate = null;
      if (date) {
        this.fromDate = new Date(date);
      }
    },
    pickEnd(date) {
      this.toDate = null;
      if (date) {
        this.toDate = new Date(date);
      }
    },
    disabledStartDate(date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return date > new Date();
    },
    disabledEndDate(date) {
      if (this.fromDate) {
        return this.fromDate > date || date > new Date();
      }
      return date > new Date();
    },
  },
}
</script>

<style scoped>

</style>
